import React from 'react'
import styled from 'styled-components';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PhoneScreen from '../components/PhoneScreen'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import appleCta from '../images/apple-cta.png';

const Section = styled.section`
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    background-color: ${(props) => props.odd ? props.theme.colors.tertiary : 'transparent'};  
    padding: 5em 0;
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    flex-direction: row;
  }
`

const ContentWrapper = styled.div`
  flex: 3;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-right: ${({ odd }) => odd ? 0 : '40px'};
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  order: -1;
  margin-bottom: 5em;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 30%;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    order: ${({ odd }) => odd ? -1 : 0};
    margin-right: ${({ odd }) => odd ? '40px' : 0};
    margin-bottom: 0;
  }
`;

const StoreLink = styled.a`
  width: 100px;
  display: block;
`

const GoogleStoreLink = styled(StoreLink)`
  width: 132px;
  margin-top: -9px;
`

const CTAWrapper = styled.div`
  max-width: 1090px;
  margin: 0 auto;
  display: flex;
`

const OptismedPage = ({ data }) => {
  // console.log('data: ', data);
  const slides = data.allContentfulOptismedSlide.edges.map(el => el.node).sort((a, b) => {
    if (a.title > b.title) {
      return 1;
    } else if (a.title < b.title) {
      return -1;
    } else {
      return 0;
    }
  });
  const postNode = {
    title: `Optismed - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Optismed - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="optismed" customTitle />
        <Container style={{ paddingBottom: 0 }}>
        <Container style={{ paddingBottom: 0 }}>
          <CTAWrapper>
            <StoreLink href="https://apps.apple.com/ch/app/optismed/id1460556943" target="_blank" rel="noopener noreferrer">
              <img src={appleCta} />
            </StoreLink>
            <GoogleStoreLink href='https://play.google.com/store/apps/details?id=app.optimusconsult.optismedApp&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
              <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </GoogleStoreLink>
          </CTAWrapper>
        </Container>
        </Container>
        {slides.map((slide, i) => (
            <Section key={slide.title} odd={i % 2 !== 0} style={{ paddingTop: i === 0 && 0 }}>
              <Container>
                <RowWrapper>
                  <ContentWrapper odd={i % 2 !== 0}>
                    <PageBody body={slide.body} />
                  </ContentWrapper>
                  <ImageWrapper odd={i % 2 !== 0}>
                    <PhoneScreen src={slide.phoneScreen.resolutions.src} />
                  </ImageWrapper>
                </RowWrapper>
              </Container>
            </Section>
        ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulOptismedSlide(filter: { node_locale: { eq: "fr" } }) {
      edges {
        node {
          title
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 320)
            }
          }
          phoneScreen {
            resolutions(width: 800) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

export default OptismedPage
